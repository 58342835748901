'use client';
import type React from 'react';
import { GlobalProps } from '@lib/global';
import { componentParser } from '@lib/componentParser';
import { ContentPageSchema } from '@lib/types/transformed/ContentPageSchema';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import Section from '@components/Atoms/Section';

export const ContentPage: React.FC<any> = ({ page, globals }: { globals: GlobalProps; page: ContentPageSchema }) => {
  if (page.type === 'text') {
    return (
      <Section name="page">
        <div className="container pt-5 pb-5">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{page.text}</ReactMarkdown>
        </div>
      </Section>
    );
  } else {
    const components = componentParser(globals, page.content);
    return <>{components}</>;
  }
};
